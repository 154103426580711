import * as React from "react";
import { connect } from "react-redux";
import { Container, Menu, Grid } from "semantic-ui-react";
import { Fragment } from "redux-little-router";
import { ToastContainer } from "react-toastify";
import { Breadcrumbs, CdnImg, EnvironmentLabel, LoadingWrapper } from "@momenta/common";
import { Forbidden } from "@momenta/common/auth";
import { ErrorBoundaryRouted } from "@momenta/common/ErrorBoundaryRouted";

import { isRequestActive } from "redux-request-loading";

import { AppState } from "./model";
import { CurrentUser } from "./auth";
import { InvoicePeriods } from "./invoicePeriods";
import { Landing } from "./Landing";
import { ContractDetails } from "./contracts/components/ContractDetails";

interface AppStateProps {
    currentUser: CurrentUser;
    loading: boolean;
}

const AppUnconnected: React.FC<AppStateProps> = ({ currentUser, loading }) => {

    const authMenu = currentUser.authenticated
        ? <Menu.Item as="a" href="/account/signout">Sign-Out</Menu.Item>
        : <Menu.Item as="a" href="/account/signin">Sign-In</Menu.Item>;

    const unAuthenticated = React.useCallback(() => currentUser.authenticated === false, [currentUser]);
    const authenticated = React.useCallback(() => currentUser.authenticated, [currentUser]);

    return (
        <>
            <div className={unAuthenticated() ? "unauthenticated" : undefined}>
                <Menu as="header" className="top-menu" borderless>
                    <Container>
                        <Menu.Item className="logo"><CdnImg src="/momenta-logo.webp" /></Menu.Item>
                        <Menu.Item><EnvironmentLabel /></Menu.Item>
                        <Menu.Menu position="right" className="account">
                            {authMenu}
                        </Menu.Menu>
                    </Container>
                </Menu>

                <Fragment forRoute="/" withConditions={unAuthenticated}>
                    <Landing />
                </Fragment>

                <LoadingWrapper loading={loading}>
                    <Container>
                        <Breadcrumbs />
                        <ToastContainer hideProgressBar />
                        <ErrorBoundaryRouted>
                            <Fragment forRoute="/">
                                <>
                                    <Fragment forRoute="/forbidden">
                                        <Forbidden />
                                    </Fragment>
                                    <Fragment forRoute="/invoicePeriods" withConditions={authenticated}>
                                        <InvoicePeriods />
                                    </Fragment>
                                </>
                            </Fragment>
                            <Fragment forRoute="/contracts" withConditions={authenticated}>
                                <ContractDetails />
                            </Fragment>
                        </ErrorBoundaryRouted>
                    </Container>
                </LoadingWrapper>
                {unAuthenticated() && <footer className="landing-footer">
                    <Container>
                        <Grid>
                            <Grid.Column computer={16} mobile={16} floated="right" textAlign="right">
                                <strong>0207 374 6600</strong>
                            </Grid.Column>
                        </Grid>
                    </Container>
                </footer>}
            </div>
        </>
    );
};

const mapStateToProps = (state: AppState): AppStateProps => {
    return {
        currentUser: state.currentUser,
        loading: isRequestActive(state, "loadCurrentUser")
    };
};

export const App = connect(mapStateToProps)(AppUnconnected);
